export const objSchmot = [
  {
    name: 'ШМОТ',
    chapter: 'ШМОТ(Имена)',
    id_book: 2,
    id_chapter: 1,
    poemNumber: 1,
    verse: '1111111111111111111111111111',
    comment: '',
  },
  {
    name: '',
    chapter: '',
    id_book: 2,
    id_chapter: 1,
    poemNumber: 2,
    verse: '222222222222222222222222222222222',
    comment: '',
  },
  {
    name: '',
    chapter: '',
    id_book: 2,
    id_chapter: 1,
    poemNumber: 3,
    verse: '33333333333333333333333333333333333333333',
    comment: '',
  }
];